import PropTypes from "prop-types"
import React from "react"
import classNames from "classnames"

const CTA = ({
  buttonCentered,
  buttonColumns,
  className,
  headingColumns,
  sideColumnIs,
  textColumns
}) => {
  const ctaClasses = classNames("columns cta-columns", className)
  const buttonClasses = classNames("columns", {
    "has-text-centered is-centered": buttonCentered
  })

  return (
    <div className={ctaClasses}>
      <div className="column">
        <div className="columns">{headingColumns}</div>
        <div className="columns">
          <div className={`column is-${sideColumnIs}`} />
          {textColumns}
          <div className={`column is-${sideColumnIs}`} />
        </div>
        <div className={buttonClasses}>{buttonColumns}</div>
      </div>
    </div>
  )
}

CTA.propTypes = {
  headingColumns: PropTypes.element,
  textColumns: PropTypes.element,
  buttonColumns: PropTypes.element,
  sideColumnIs: PropTypes.number
}

export default CTA
